
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useMemo } from 'react'
import Head from 'next/head'
import getConfig from 'next/config'

import PlausibleProvider from 'next-plausible'
import { DefaultSeo } from 'next-seo'
import { SWRConfig } from 'swr'
import { IdProvider } from '@radix-ui/react-id'

import NProgress from 'nprogress'
import Router from 'next/router'
import fetcher from '../lib/data/fetcher'

import '../styles/main.scss'

import 'swiper/css/bundle'

import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const {
  publicRuntimeConfig: { SITE_URL, GTM_ID },
} = getConfig()

// Page loading progress bar
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

export const App = ({ Component, pageProps }) => {
  const ogImage = {
    url: `${SITE_URL}/images/paymenttools-og.jpg`,
  }

  const platform = useMemo(() => {
    try {
      return (
        navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
      )
    } catch (e) {
      return 'unknown'
    }
  }, [])
  const iOS = useMemo(
    () => platform && /iPad|iPhone|iPod/.test(platform),
    [platform]
  )
  const viewport = useMemo(
    () =>
      'initial-scale=1.0, width=device-width' +
      (iOS ? ', maximum-scale=1' : ''),
    [iOS]
  )

  useEffect(() => {
    if (GTM_ID) {
      TagManager.initialize({ gtmId: GTM_ID })
    }
  }, [])

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content={viewport} />
        <meta name="theme-color" content="#FAB608" />
      </Head>
      <DefaultSeo
        title={undefined}
        titleTemplate={`%s – paymenttools`}
        defaultTitle="paymenttools"
        openGraph={{
          type: 'website',
          title: 'paymenttools',
          description: '',
          images: [ogImage],
        }}
        twitter={{
          handle: '@paymenttools',
          site: '@paymenttools',
          cardType: 'summary_large_image',
        }}
      />
      <SWRConfig
        value={{
          refreshInterval: 0,
          fetcher,
        }}
      >
        <IdProvider>
          <PlausibleProvider
            domain="paymenttools.com"
            trackOutboundLinks
            // trackLocalhost
          >
            <Component {...pageProps} />
          </PlausibleProvider>
        </IdProvider>
      </SWRConfig>
    </>
  )
}

const __Page_Next_Translate__ = App


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  